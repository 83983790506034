
import Vue from 'vue'
import { favicons, images, metadata } from '~/assets/ts/utils/metadata'

export default Vue.extend({
  head(this: Record<string, any>) {
    return metadata(
      {
        images: images(require('@/assets/images/ogImage.png')),
        description: this.$t('sa.subtitle').toString(),
        favicons: favicons('sa'),
        bodyAttrs: {
          class: this.modalOpen ? 'overflow-hidden' : '',
        },
      },
      this
    )
  },
  computed: {
    modalOpen(): boolean {
      return this.$store.getters['site/modalOpen']
    },
  },
})
